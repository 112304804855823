import React, { useEffect, useRef, useState } from "react"
import { PageProps } from "gatsby"
import firebase from "gatsby-plugin-firebase"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button";

import {
  BannerContainer, BannerTitle, FormContainer, Input, Textarea,
  ProjectBudgetContainer, OptionsContainer, Label, SendButton, ContentWrap
} from "../styles/contact";

import ContactBg from "../svgs/contactbg.svg";
import gsap from "gsap";

const Banner = () => {
  const titleRef = useRef(null);

  useEffect(() => {
    gsap.to(titleRef.current, {
      duration: .5,
      delay: .7,
      opacity: 1,
      y: 0,
      ease: "power3.easeOut"
    });
    gsap.to(".contact-bg", {
      duration: .7,
      delay: .3,
      opacity: 1,
      y: 0,
      ease: "power3.easeOut"
    });
  }, []);

  return (
    <BannerContainer>
      <ContactBg className="contact-bg" />
      <BannerTitle ref={titleRef}>
        Got a project idea?<br />
        Looking for business transformation?<br />
        Turn your dream into reality with Tabs & Spaces
      </BannerTitle>
    </BannerContainer>
  );
}

const InterestedIn = ({ onChange, value }) => {
  const data = ["Website design", "Mobile app design", "Hybrid mobiles app development",
    "App from scratch", "Explainer videos", "Static web development", "Wordpress websites", "Consultations"];

  const selectItem = item => {
    const temp = [...value];
    const _index = temp.indexOf(item);
    if (_index >= 0) {
      temp.splice(_index, 1);
    } else {
      temp.push(item);
    }
    onChange(temp);
  };

  const isSelected = item => value.indexOf(item) >= 0;

  return (
    <ProjectBudgetContainer>
      <Label>I'm interested in</Label>
      <OptionsContainer>
        {data.map(i => <Button isActive={isSelected(i)} onClick={e => selectItem(i)}>{i}</Button>)}
      </OptionsContainer>
    </ProjectBudgetContainer>
  );
}

const ProjectBudget = ({ onChange, value }) => {
  const data = ['< 500', '500 - 1k', '1k - 2k', '2k - 3k', '3k - 4k'];
  return (
    <ProjectBudgetContainer>
      <Label>Project Budget (USD)</Label>
      <OptionsContainer>
        {data.map(i => <Button isActive={value === i} onClick={e => onChange(i)}>{i}</Button>)}
      </OptionsContainer>
    </ProjectBudgetContainer>
  );
}

const ContactForm = () => {
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [interestedIn, setInterestedIn] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [budget, setBudget] = useState('');
  // const [attachment, setAttachment] = useState(null);

  // const fileData = () => {
  //   if (attachment) {
  //     return (
  //       <div>
  //         <h2>File Details:</h2>
  //         <p>File Name: {attachment.name}</p>
  //         <p>File Type: {attachment.type}</p>
  //       </div>
  //     );
  //   }
  // };

  const onSubmit = (ev) => {
    ev.preventDefault();
    setIsSendingMail(true);
    const formData = new FormData();
    // if (attachment) {
    //   formData.append(
    //     "upload",
    //     attachment,
    //     attachment.name
    //   );
    // }
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', description);
    formData.append('interestedIn', interestedIn.join(','));
    formData.append('projectBudget', budget);

    // Send data using XHR
    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://formspree.io/f/mvovdjak');
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        setInterestedIn([]);
        setName('')
        setEmail('')
        setDescription('')
        setBudget('')
        alert('Mail sent successfully');
      } else {
        alert('Error sending mail');
      }
      setIsSendingMail(false);
    };
    xhr.send(formData);
  };

  return (
    <FormContainer onSubmit={onSubmit}>
      <InterestedIn value={interestedIn} onChange={ev => setInterestedIn(ev)} />
      <Input type="text" value={name} onChange={ev => setName(ev.target.value)} placeholder="Your name" />
      <Input type="email" value={email} onChange={ev => setEmail(ev.target.value)} placeholder="Your email" />
      <Textarea value={description} onChange={ev => setDescription(ev.target.value)} placeholder="Tell us about your project" />
      <ProjectBudget value={budget} onChange={ev => setBudget(ev)} />
      {/* <input type="file" onChange={ev => setAttachment(ev.target.files[0])} />
      {fileData()} */}
      {
        isSendingMail ? <SendButton>Sending request...</SendButton> : <SendButton onClick={onSubmit}>Send Request</SendButton>
      }
    </FormContainer>
  );
};

const ContactPage: React.FC<PageProps<any>> = ({ data, path }) => {
  const contentRef = useRef(null);

  useEffect(() => {
    if (firebase) {
      firebase.analytics().logEvent('visited_contact_us')
    }
  }, [firebase]);

  useEffect(() => {
    gsap.to(contentRef.current, {
      duration: .5,
      delay: 1.2,
      opacity: 1,
      y: 0,
      ease: "power3.easeOut"
    });
  }, []);

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Banner />
      <ContentWrap ref={contentRef}>
        <ContactForm />
      </ContentWrap>
    </Layout>
  );
}

export default ContactPage
